import "./src/css/index.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "flag-icon-css/css/flag-icons.min.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location && location.state) {
        location.state.referrer = prevLocation ? prevLocation.pathname : null
    }
}
