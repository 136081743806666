exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-archive-tags-js": () => import("./../../../src/templates/blog-archive-tags.js" /* webpackChunkName: "component---src-templates-blog-archive-tags-js" */),
  "component---src-templates-insights-archive-js": () => import("./../../../src/templates/insights-archive.js" /* webpackChunkName: "component---src-templates-insights-archive-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

